<template>
  <div>
    <dashboard-page-title
      @addEvent="$router.push({ name: 'addWithdraw' })"
      :showAddBtn="false"
      >{{ $t("main.sidebar.transactions") }}</dashboard-page-title
    >
    <main-table
      :fields="fields"
      list_url="merchant/wallet/wallet-transactions"
      :paginationName="$t('main.sidebar.transactions')"
    ></main-table>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      fields: [
        { label: this.$t("main.amount"), key: "amount", class: "text-start" },
        {
          label: this.$t("main.status.status"),
          key: "status",
          class: "text-start",
          type: "translation",
          objectTranslation: "withdraw.statuses",
        },
        {
          label: this.$t("main.payment_method"),
          key: "payment_method_type",
          class: "text-start",
          type: "translation",
          objectTranslation: "withdraw.methods",
        },
        {
          label: this.$t("main.type"),
          key: "type",
          class: "text-start",
          type: "translation",
          objectTranslation: "withdraw.types",
        },
        {
          label: this.$t("main.created_at"),
          key: "created_at",
          class: "text-start",
          type: "date",
        },
      ],
    };
  },
  methods: {
    addMerchant() {
      console.log("hello");
    },
  },
};
</script>
